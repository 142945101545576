import React from "react";
import Book from "../subpage_src/components/Book";
import data from '../data/Data';
import Layout from "../subpage_src/components/Layout";
import {BuddhismCover} from "../subpage_src/components/partials/book_covers/BuddhismCover";
import tableOfContents from "../data/table_of_contents/buddhism.pdf";

const BuddhismAndPsychology = () => {
    return (
        <Layout lang={"en"}>
            <Book
                lang={"en"}
                Cover = { BuddhismCover }
                bookData = {data.buddhism}
                pdfFile={tableOfContents}
            />
        </Layout>
    )

}

export default BuddhismAndPsychology;